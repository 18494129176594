.hero {
	padding: 20px;
	min-height: 100vh;
	background-image: url('../img/bg-hero-m.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	
	.eventos & {
		background-image: url('../img/hero-events-m.jpg');
	}
	.promociones & {
		background-image: url('../img/hero-promotion-m.jpg');
	}

	.chapelco &.turismo{
		background-image: url('../img/hero_chapelco_mb.jpg');
	}

	.recoleta &.turismo{
		background-image: url('../img/hero_recoleta_mb.jpg');
	}

	.iguazu &.turismo{
		background-image: url('../img/hero_iguazu_mb.jpg');
	}

	.esmeralda &.turismo{
		background-image: url('../img/hero_esmeralda_mb.jpg');
	}

	@include breakpoint(phablet) {
		padding: 40px;

		.chapelco &.turismo{
			background-image: url('../img/hero_chapelco_ipad.jpg');
		}
		.recoleta &.turismo{
			background-image: url('../img/hero_recoleta_ipad.jpg');
		}
		.iguazu &.turismo{
			background-image: url('../img/hero_iguazu_ipad.jpg');
		}
		.esmeralda &.turismo{
			background-image: url('../img/hero_esmeralda_ipad.jpg');
		}
	}
	@include breakpoint(tablet) {
        padding: 60px;
        background-image: url('../img/bg-hero.jpg');
		
		.eventos & {
			background-image: url('../img/hero-events.jpg');
		}
		.promociones & {
			background-image: url('../img/hero-promotion.jpg');
		}

		.chapelco &.turismo{
			background-image: url('../img/hero_chapelco_tablet.jpg');
		}
		.recoleta &.turismo{
			background-image: url('../img/hero_recoleta_tablet.jpg');
		}
		.iguazu &.turismo{
			background-image: url('../img/hero_iguazu_tablet.jpg');
		}
		.esmeralda &.turismo{
			background-image: url('../img/hero_esmeralda_tablet.jpg');
		}
	}

	@include breakpoint (laptop){
		.chapelco &.turismo{
			background-image: url('../img/hero_chapelco_desktop.jpg');
			min-height: 64vh; 
		}
		.recoleta &.turismo{
			background-image: url('../img/hero_recoleta_desktop.jpg');
			min-height: 64vh; 
		}
		.iguazu &.turismo{
			background-image: url('../img/hero_iguazu_desktop.jpg');
			min-height: 64vh; 
		}
		.esmeralda &.turismo{
			background-image: url('../img/hero_esmeralda_desktop.jpg');
			min-height: 64vh; 
		}
	}

	@include breakpoint(desktop) {
		padding: 70px 20px 0;
		background-image: url('../img/bg-hero-h.jpg');
		
		.eventos & {
			background-image: url('../img/hero-events-h.jpg');
		}
		.promociones & {
			background-image: url('../img/hero-promotion-h.jpg'); 
		}
	}

	.gracias & {
		background: linear-gradient( $cream, $cream-dark);
		color: $grey;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		text-align: center; 

		h2 {
			position: relative;
			z-index: 2;
		}

		&:before {
			content: none;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(0,0,0,.7) ;
			z-index: 1;
		}

		@include breakpoint(tablet) {
			min-height: 75vh; 
		}

		@include breakpoint(desktop) {
			// background-image: url('../img/bg-hero-h.jpg');
			// background-repeat: no-repeat;
			// background-size: cover;
		}
	}
}

// video {
//     object-fit: cover;
//     width: 100%;
//     height: 100vh;
//     position: absolute;
//     top: 0;
//     left: 0;
//     display: block;
//     z-index: 2;
// }

/* Make the youtube video responsive */

.iframe-container {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%;
	height: 0;

	.iguazu &{

		&:before{
			content:url('../img/icons-exp-20.svg');
			position: absolute;
			top: 6%;
			right: 4%;
			width: 75px;
			z-index: 3;
		}
	}

	.chapelco &{

		&:before{
			content:url('../img/icons-exp-18.svg');
			position: absolute;
			top: 6%;
			right: 4%;
			width: 75px;
			z-index: 3;
		}
	}

	.esmeralda &{

		&:before{
			content:url('../img/icons-exp-19.svg');
			position: absolute;
			top: 6%;
			right: 4%;
			width: 75px;
			z-index: 3;
		}
	}

	.recoleta &{

		&:before{
			content:url('../img/icons-exp-17.svg');
			position: absolute;
			top: 6%;
			right: 4%;
			width: 75px;
			z-index: 3;
		}
	}

	@include breakpoint(tablet) {
	
		.iguazu &{

			&:before {
				top: 40px;
				right: 40px; 
				width: 100px;
			}
		}

		.chapelco &{

			&:before {
				top: 40px;
				right: 40px; 
				width: 100px;
			}
		}

		.esmeralda &{

			&:before {
				top: 40px;
				right: 40px; 
				width: 100px;
			}
		}

		.recoleta &{

			&:before {
				top: 40px;
				right: 40px; 
				width: 100px;
			}
		}
	} 
}

.topspace {
	display: block;
	// height: 64px;
	width: 100%;  
}

.iframe-container iframe{
	position: absolute;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
}