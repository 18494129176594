@include breakpoint(laptop) {
        
    .come-in {
        transform: translateY(250px);
        animation: come-in 0.8s ease forwards;
    }
    .come-in:nth-child(odd) {
        animation-duration: 0.6s;
    }
    .already-visible {
        transform: translateY(0);
        animation: none;
    }
}

@keyframes come-in {
    to { transform: translateY(0); }
}