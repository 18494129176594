.pool {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f1eada;
    
    &_list {
        margin-top: 42px;
        width: 75%;
    }
    
    &_list li {
        margin-bottom: 20px;
    }
    
    &_list picture {
        position: relative;
        padding-top: 35px;
    }
    
    &_list figcaption {
        position: absolute;
        background: #007A5C;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        color: #fff;
        font-family: Lora;
        font-size: 18px;
        padding: 20px 25px;
        border-radius: 10px;
    }
    
    &_list figcaption::before {
        content: '';
        background: #007A5C;
        position: absolute;
        bottom: -13px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%) rotate(45deg);
        width: 26px;
        height: 26px;
    }
    
    h2 {
        background: #007A5C;
        color: #fff;
        padding: 0 15px;
        margin-bottom: 20px;
        font-size: 36px;
    }
    
    p {
        max-width: 60%;
        color: #666565;
        text-align: center;
        font-family: Fira Sans;
        font-size: 20px;
        margin: 0 auto;
        position: relative;
    }

    @include breakpoint(phablet) {
        padding: 40px 0; 

        h2 {
            font-size: 32px;
            margin-bottom: 36px;
        }

        &_list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            width: 100%; 
            
            li {
                margin: 0;
            }
 
            picture {
                height: 100%;
                position: relative;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    @include breakpoint(tablet) {
        padding: 60px 0;
        
        p { 
            max-width: 860px;
        }

        &_list {
            margin-top: 42px;
            grid-template-columns: repeat(3,1fr);
            position: relative;
        }

        &_list li {
            max-width: unset;
            margin:0;
        }
    }

    @include breakpoint(laptop) {
        padding: 80px 0;

        h2  {
            font-size: 36px;
        }

        p {
            font-size: 22px;
        }

        &_list{
            gap: 20px;
        
            picture {
                overflow: hidden; 
            }
            
            img {
                transform: scale(1);
                transition: all .6s ease;
                
                &:hover{
                    transform: scale(1.05);
                }
            }
        }
    }

    @include breakpoint(desktop) {
        padding: 100px 0;
        
    }
}