.contact{
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: $cream;

    h2 {
        margin-bottom: 20px;
        color:$grey;
        font-family: 'Lora';
        font-weight: 400;
    }

    p.description {
        margin-bottom: 42px;
        max-width: 460px;
    }

    .advice {
        margin: 46px auto 0;
        max-width: 610px;        
        font-size: 14px;

        a {
            color: $grey;
            border-bottom: $grey 1px solid;
            transition: .3s all ease;

            &:hover {
                border-color: transparent;
            }
        }
    }

    .items {
        margin-bottom: 42px;
        color: $grey;

        li {margin-bottom: 10px;}

        a {
            color: $grey;

            svg {
                fill:$grey;
                height: 16px;
                width: auto;
                margin-right: 8px; 
            }  

            &:hover {
                color: $black;
            }
        }
    }

    #formContacto {
        
        .button {
            width: fit-content;
            color:$white;
            background-color: $grey;
            padding: 10px 20px;
            border: none;
            font-weight: 400;
            margin:0 auto;
        }
    }

    &.interna {
        padding: 120px 20px 60px;        
    }

    @include breakpoint(mobilelandscape) {

        form{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 20px;
            
            input, select {
                margin: 0;
            }
            .button {
                grid-column: 1/3;
                margin:0 0 0 auto;             
            }
            textarea{
                grid-column: 1/3;

            }
        }
    }

    @include breakpoint(phablet) {
        padding:40px;

        h2{
            font-size: 32px;
            margin-bottom: 30px;
        }

        form{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 20px;

            input, select{
                margin: 0;

                &[id=nombre] {
                    grid-column: 1/3;

                    .iguazu &,
                    .chapelco &,
                    .esmeralda &,
                    .recoleta &,
                    .eventos:not(.promociones) & {
                        grid-column: 1/2; 
                    }
                }
            }

            .button {
                grid-column: 1/3;
                margin:0 0 0 auto;             
            }

            textarea{
                grid-column: 1/3;

            }
        }
    }
    @include breakpoint(tablet) {
        padding:60px;
    }

    @include breakpoint(laptop) {
        padding:80px;

        h2{
            font-size: 36px;
        }

        form{
            min-width: 650px;
        }

        &.interna {
            padding: 160px 0 100px;        
        }        
    }

    @include breakpoint(bigdesktop) {
        padding:100px;

        h2{
            font-size: 36px;
        }
    }
}