.check {
    padding:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $cream-dark;
    bottom: 0;
    left: 0;
    width: 100%;
    animation: formIn .5s ease-out; 
    position: fixed;
    z-index: 10; 

    .iguazu & {
        background-color: $iguazu;

        form {
  
            .button {
                border-color:$white;  
                background-color:$white;
                
                span {
                    color:$iguazu;
                }
                
                &::before{
                    background-color:$iguazu;
                    color:$white;
                }
            }
        }
    }

    .chapelco &{
        background-color: $chapelco;

        form{
  
            .button {
                border-color:$white;  
                background-color:$white;

                span {
                    color:$chapelco;
                }

                &::before{
                    background-color: $chapelco;
                    color: $white;
                }
            }
        }        
    }

    .esmeralda &{
        background-color: $esmeralda;

        form{
  
            .button {
                border-color: $white;  
                background-color: $white;

                span {
                    color: $esmeralda;
                }

                &::before{
                    background-color: $esmeralda;
                    color: $white;
                }
            }
        }        
    }

    .recoleta &{
        background-color: $recoleta;

        form{
  
            .button {
                border-color: $white;  
                background-color: $white;

                span {
                    color: $recoleta;
                }

                &::before{
                    background-color: $recoleta;
                    color: $white;
                }
            }
        }        
    }
          
    .send-button { 
        pointer-events: none;
    }    

    &.inactive{
        animation: formOut .5s ease-out;
        bottom: -100px;
    } 

    &.desplegado{
        height: 100%;

        .form__toggle{
            width: 27px;
            height: auto;
            top: 80px;
            right: 20px;
        }

        .header__toggle-line {

            &:nth-child(1) {
                transform: translateY(8px) rotate(45deg);
                background: $white;
            }
            &:nth-child(2) {
                background: $white;
                opacity: 0;
            }
            &:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
                background: $white;
            }
        }

        .form-hidden{
            display: flex;
            justify-content: center;
        }

        form {

            .date {
                margin-bottom: 10px;
            }
          
            .send-button {
                pointer-events: auto;
            }             
        }
    }

    &.home.desplegado {
    
        .header__toggle-line {

            &:nth-child(1) {
                background: $grey;
            }
            &:nth-child(2) {
                background: $grey;
            }
            &:nth-child(3) {
                background: $grey;
            }
        }        
    }

    .form__toggle {
        width: 100%;
        height: 100%;
        position: absolute;

        .header__toggle-line{
            transition: background 0s, box-shadow 0s;
            background: none;
            box-shadow: none;
        }
    }

    &.visible{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    form {
        width: auto;
       
        .date {
            display: flex;
            align-items: center;

            &:nth-child(1){
                margin-bottom: 10px;
            }

            select {
                border-radius: 10px;
            }

            img {
                width: 22px;
                height: auto;
                margin-right: 12px;
            }

            input, select {
                margin-bottom: 0;
            }

            &.children, &.adults {
                
                img {
                    width: 26px;
                }
            }
        }

        .button {
            color:$white;
            font-weight: 600; 
        }

        .form-hidden{
            display: none;
        }
    }

    @include breakpoint(mobilelandscape) {

        .booking-mask{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 20px;

            input{
                margin-bottom: 0;
            }

            .date{
                &:nth-child(1){
                    margin-bottom: 0px;
                }
            }
        }

        .form-hidden{ 

            &:nth-child(2) {
                display: flex;
            }
        }
    }

    @include breakpoint(phablet) {

        .booking-mask{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 20px;

            input{
                margin-bottom: 0;
            }

            .date{
                &:nth-child(1){
                    margin-bottom: 0px;
                }
            }
        }

        .form-hidden{
            &:nth-child(2){
                display: flex;
            }
        }
    }

    @include breakpoint(tablet) {

        .booking-mask{
            display: flex;
            grid-template-columns: repeat(8,1fr);

            .form-hidden{
                display: flex;
                align-items: center; 
            }

            .date {
                &.active{
                    margin-bottom: 0px;
                }
            } 

            .promo {

                input {
                    width: 108px;
                }
            }
        }

        &.desplegado{
            height: unset;
    
        }

        form {  

            .date {
                margin-bottom: 0px;

                &.in, &.out { 
                    
                    input {
                        width: 120px; 
                    }
                }
            }

            .button {
                font-size: 15px;
                            
                &:before, & > span {
                    padding: .8em;
                }
            }
        } 

        .send-button {
            pointer-events: auto;
        }         

        .form__toggle{
            display: none;
        }
    }
}

@keyframes formOut {
    0%   { bottom: 0; }
    100% { bottom: -100px; }
}
@keyframes formIn {
    0%   { bottom: -100px; }
    100% { bottom: 0; }
}  