*, *:before, *:after { box-sizing: border-box; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
img {
	display: block;
	width: 100%;
}
h1, h2, h3, h4, h5, h6 {
	font: 500 32px $font-title;
	line-height: 1.4;
}
ul, li {
	list-style: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, picture, svg {
	display: block;
}
body {
	font: 300 16px $font-base;
	color: $black;
	line-height: 1;
	background: $white;
	position: relative;
	overflow-x: hidden;

	&.open-menu { 
		overflow-y: hidden; 
	}
}
a {
	text-decoration: none;
}
p {
	line-height: 1.4;
	
	em {
		font-style: italic;
	}
}
sup { 
	font-size: .7em;
}
strong {
	font-weight: 600;
}
input, textarea, select {
	color: $grey;
	font-size: 16px;
	font-weight: 500;
	width: 100%;	
	background: $white;
	border: 1px $white solid;
	border-radius: 10px;
	padding: 12px;
	margin-bottom: 12px; 
	transition: all .2s ease-in-out;
	
	&::placeholder {
		// color: $grey;
	}	
	&::-moz-placeholder {
	  opacity: 1;
	}
	&:focus {
		border-color: $grey;
		
		&::placeholder {
			color: $white;
		}
	}
	&.error {
		border-color:$cream;
	}
}
select {
	appearance: none;
	background: $white url('../img/arrow.svg');
	background-repeat: no-repeat;
	background-size: 10px;
	background-position: calc(100% - 12px) center;
	padding-right: 36px; 
}	
.button {
	overflow: hidden;
	padding: 0;
	width: 100%;
	color: $black;
	font-weight: 500;
	border: 2px solid;
	border-color: $grey;
	border-radius: 10px;
    background: $grey;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;

	&:before, & > span {
		padding: .8em 2em;
		transition: transform 0.3s;
		transition-timing-function: ease;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}    
	
	& > span {
	    display: block;
	    vertical-align: middle;
		color: $white;
	}

	&:before {
		color: $grey;
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $cream; 
		transform: translate3d(-100%, 0, 0);	   
	}

	&:hover {
 
		&:before {
		    transform: translate3d(0, 0, 0);
		}
		& > span {
			transform: translate3d(0, 100%, 0);
		}
	}
}
a.button { 
	display: block;
	width: 230px;
	margin: 0 auto; 
	text-align: center;
}
.grecaptcha-badge {
	opacity: 0;
}
.whatsapp-button {
	width: 48px;
	height: 48px;
	position: fixed;
	bottom: 120px;
	right: 30px;
	z-index: 12;
	opacity: 0; 
	visibility: hidden;
	transition: all .3s ease-in-out;

	.icon {
		transition: all 400ms ease;
		transform: scale(1);
	}

    &:hover .icon {
    	transform: scale(1.1);
    }		

	&.show {
		opacity: 1;
		visibility: visible;
	}

    @include breakpoint(tablet) {
		width: 52px;
		height: 52px;
		// bottom: 42px;
		right: 42px;

		.icon {
			filter: drop-shadow(2px 2px 4px rgba(0,0,0,.4));
		}
    }
}