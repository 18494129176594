.header {
	display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
	position: absolute;
	z-index: 20;
	padding: 20px;
	background: $cream-dark;

	.logo {
		width: 85px;
		height: auto;
		display: block;
	}

	.call-me {
		margin-right: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $grey;

		li {

			&:last-child {
				margin-left: 32px; 
			}
		}
		
		span {
			line-height: 24px;
			display: none;
		}

		a {
			color: $grey;
			pointer-events: all;
			display: flex;

			svg {
				fill:$grey;
				height: 24px;
			}
		}		
	} 

	.right {
		display: flex;
		align-items: center;
	}

	&__toggle {
	    width: 27px;
	    
	    .gracias & {
	    	display: none;
	    }
	}

	&__toggle-line {
	    display: block;
	    background: $grey;
	    width: 100%;
	    height: 4px; 
	    margin-bottom: 4px;
	    opacity: 1; 
	    transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms;    
		
		&:nth-child(1) {
		    transform-origin: center;
		}
		&:nth-child(3) {
		    transform-origin: center;
		}
	}    

	&.is-active {
	    position: fixed;
	    z-index: 20;
		
		.header__toggle-line {

			&:nth-child(1) {
			    transform: translateY(8px) rotate(45deg);
			    background: $grey;
			}
			&:nth-child(2) {
			    background: $grey;
			    opacity: 0;
			}
			&:nth-child(3) { 
			    transform: translateY(-8px) rotate(-45deg);
			    background: $grey; 
			}
		}
	}

	&.fixed {
	    padding: 20px;
	    position: fixed;
	    animation: headerFix .5s ease-out;
	    background: $white;
	    align-items: center;
		box-shadow: 0px -3px 9px rgba(0,0,0,0.2);
	}	

	@include breakpoint(tablet) {
		padding: 20px 40px;
		align-items: center;

		.logo {
			width: 125px;
			
			.gracias & {
		    	display: block;
		    }
		} 

		.call-me {
			margin-right: 42px;

			span {
				display: inline-block; 
			}

			li:first-child {
				margin-right: 16px; 
			}			
		}		
	}
}

section.menu {
    position: fixed;
    top: 0; 
    left: 100%;
    z-index: 20; 
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 80px 40px 0; 
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
    background: $cream;
    transition: opacity 400ms ease, left 100ms ease 600ms;
	pointer-events: none;

	&.is-visible {
	    left: 0;
	    opacity: 1;
	    transition: left 100ms ease, opacity 400ms ease 200ms;
	    overflow: hidden;
	}
	
	.menu-inner {
	    margin: 0;
		text-align: right;
	}

	ul {
		width: calc(100vw - 120px);
		list-style: none;
		margin: 0;
		padding: 0;
		
		li {
			font-size: 20px;
			padding: 5px 0px;   
			margin-bottom: 22px;
			border-bottom: .5px $cream-dark solid;
			transition: all .3s ease-in;
			
			a {
				line-height: 24px; 
				color: $grey;
				pointer-events: all;
			}
		}
	}

	.gracias & { display: none;}

	@include breakpoint(mobilelandscape) {

		.menu-inner {
			overflow-y: scroll;
		}
	} 
	@include breakpoint(tablet) {
		
		&.is-visible { 
			left: 70%;
			width: 30%;
		}

		ul {
			width: calc(30vw - 80px);

			li {
				font-size: 22px;
			}
		}

		.sections {
			margin-top: 32px;

			li {
				font-size: 18px;
			}
		}
	}
	@include breakpoint(laptop) {	
		
		ul {

			li { 

				&:hover, &.active {
					border-color: $grey;

				}
			}
		}
	}
}

@keyframes headerFix {
  0%   { top: -100px; }
  100% { top: 0; }
}