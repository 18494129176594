.live-in {
    padding-top: 40px;
    flex-direction: column;
    align-items: center;
    background-color: $cream;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;    
    
    h2{
        padding:0 20px;
        font-size: 24px;
        font-family: 'Lora';
        margin:0 auto;
        margin-bottom: 10px;
        color: $grey;
        text-align: center;

        span {
            font-style: italic;
            font-weight: bold;
        }
    }

    p {
        padding:0 20px;
        color: $grey;
        text-align: center;
        font-family: 'Fira Sans';
        margin:0 auto;
        margin-bottom: 20px;
        position: relative;
    }

    .float-icon {

        &::before {
            content:url('../img/icon-exp-18.svg');
            position: absolute;
            top: 100%;
            left:0;
            width: 100px;
            z-index: 3;
        }
    }

    .swiper {
        margin-top: 42px;

        .swiper-wrapper{

            .swiper-slide{
                //max-height: 70vh;
                background-repeat: no-repeat;
                background-position: center bottom;
                background-size: cover;
            }
        }

        &-pagination{
            &-bullet{
                background:$grey;
                width: 18px;
                height:18px;
            }
        }
    }

    &_list {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        li {
            width: 60%;

            &.high{
                position: relative;
                width: 50%;
                padding-bottom: 50%;

                img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            &.long{
                width: 70%;
                
            }

            &.short {
 
                img{
                    width: 100%;
                    height: 350px;
                    object-fit: cover;
                    object-position: center;
                }
            }

            &.down{
                position:relative;
                top:-40px;
            }

            &:nth-child(1){
                margin-right: auto;
                margin-left:10px;
            }
            &:nth-child(2){
                margin-left: auto;
                margin-right:10px;
            }

            &.right{
                margin-left: auto;
                margin-right:10px;
            }
            &.left{
                margin-right: auto;
                margin-left:10px;
            }
        }
    }

    .chapelco & {
        background-image: url('../img/bg-slide-chapelco-m.jpg') ;

        .float-icon {

            &::before {
                content:url('../img/icon-exp-18.svg');
            }
        }

        .swiper {
            .swiper-wrapper{
                .swiper-slide{
                    &:nth-child(1){
                        .live-in_list {

                            li {
                                &:nth-child(1){
                                    z-index:3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .iguazu & {
        background-image: url('../img/bg-slide-experience-iguazu-m.jpg');

        .float-icon {

            &::before{
                content:url('../img/icon-exp-20.svg');
            }
        }
    }    
    
    @include breakpoint(mobilelandscape) {

        p{
            &::before{
                left:12%;
            }
        }
        
        &_list {
            max-width: 70%;
            margin:0 auto;
        }

        .swiper{
            .swiper-wrapper{
                .swiper-slide{
                    //max-height: 150vh;
                }
            }
        }

    }

    @include breakpoint(phablet) {
        padding-top: 40px;
        background-size: cover;

        h2 {
            padding: 0 40px;
            font-size: 32px;
        }

        p {
            padding: 0 40px;
        }

        .float-icon {
            
            &:before{
                left:12%;
            }
        }

        &_list {
            max-width: 70%;
            margin:0 auto;
        }

        .iguazu & {
            background-position-y: -50%;
        }
    }

    @include breakpoint(tablet) {
        padding-top: 60px;
        background-size: contain;

        .iguazu & {
            background-image: url('../img/bg-slide-experience-iguazu.jpg') ;
            background-position: bottom;
        }

        .chapelco & {
            background-image: url('../img/bg-slide-chapelco.jpg');
        }

        h2{
            padding:0 60px;
        }

        p {
            max-width: 70%;
        }

        &_list{
            max-width: 75vw;
        }

        .swiper{
            &::before{
                width:150px;
            }
            .swiper-wrapper{
                .swiper-slide{
                    //min-height: 530px;
                    // background-image: url("../img/slider1-1.png") !important;
                    background-repeat: no-repeat;
                    background-position: center bottom;
                    background-size: cover;
                }
            }
        }
    }

    @include breakpoint(laptop) {
        padding-top: 80px;

        h2  {
            font-size: 36px;
            padding:0 80px;
        }

        p {
            padding:0 80px;
            font-size: 18px;

            &::before{
                left:13%;
                margin-left:0px;
            }
        }

        &_list{
            padding:0 80px;

            li.down{
                top: -100px;
            }
        }

    }

    @include breakpoint(desktop) {
        p {

            &::before{
                left:9%;
                margin-left:0px;
            }
        }
    }

    @include breakpoint(bigdesktop) {
        padding-top: 100px;

        h2{
            padding:0 100px;
        }
        p{
            padding:0 100px;
            &::before{
                left:10%;
                
            }
        }
        &_list{
            padding:0 100px;
            max-width: 60vw;
        }
        .swiper{
            &::before{
                left:25%;
            }
        }
    } 
}