.recreation{
    background: $white;
    padding: 40px 20px 20px;
    color: $grey;
    text-align:center;

    h2 {
        font-weight: 400;
        font-size: 24px;
        font-family: 'Lora';
        margin-bottom: 26px;
    }

    &_list{

        li{
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 30px;

            figure{
                display: grid;
                grid-template-columns: repeat(1,1fr);
                border-radius: 10px;
                background-color: $cream;

                figcaption{
                    padding:20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    h3{
                        margin-bottom: 5px;
                        font-size: 24px;
                        font-weight: 400;
                    }
                    p{
                        font-family: 'Fira Sans';
                    }
                }
            }
        }    
    }

    @include breakpoint(mobilelandscape) {

        &_list{
            li{
                figure{
                    grid-template-columns: repeat(2,1fr);
                }
            }
        }

    }

    @include breakpoint(phablet) {

        h2{
            padding:0 40px 0px;
            font-size: 32px;
            margin-bottom: 20px;
        }

        &_list{
            li{
                figure{
                    grid-template-columns: repeat(2,1fr);

                    figcaption{
                        p{
                            padding:0 40px 0px;
                            //margin-bottom: 30px;
                        }
                    }
                }
            }
        }

    }

    @include breakpoint(tablet) {

        h2{
            padding: 0px 60px 0px;
            max-width: 1180px;
            margin:0 auto 30px;
        }

        &_list{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 10px;
            align-items: center;

            li{
                figure{

                    figcaption{
                        padding:5px 10px;
                        
                        p {
                            padding: 0px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(laptop) {
        padding: 60px 80px;

        h2{
            font-size: 36px;
        }

        &_list{
            column-gap: 30px;            
        }
    }

    @include breakpoint(desktop) {

        &_list{
            column-gap: 40px;
            
            li{
                figure{

                    figcaption{
                        padding: 10px 20px;
                    }
                }
            }
        }
    }
    
    @include breakpoint(bigdesktop) {

        
        h2{
            font-size: 36px;
        }
        &_list{
            
            li{
                figure{

                    figcaption{
                        padding: 10px 30px;
                    }
                }
            }
        }
    }
}