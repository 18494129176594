.footer {

	&_socials{
		padding: 60px 20px 30px;
		text-align: center;
		background:$cream-dark;
		color:$grey;

		.column {
			
			h2 img {
				height: 62px;
				margin: 0 auto;
				margin-bottom: 30px;
			}

			h3 {
				font-size: 22px;
				font-family: $font-base;
				font-weight: normal;
				margin-bottom: 26px;
			}

			.menu-footer {
				font-size: 22px;
				font-family: $font-title;
				display: flex;
				flex-direction: column;
				gap: 22px;
			}

			article {
				margin-bottom: 48px;
			}

			.items-social {
				display: flex;
				align-items: center;
				justify-content: center;
				width: fit-content;
				margin-bottom: 42px;

				li {
					margin: 0 20px;

					svg {
						fill:$grey;
					}
				}

				+ .menu-footer {
					font-family: $font-base; 
					font-size: 18px;
				}
			}

			// &:nth-child(2) {
			// 	display: grid;
			// 	grid-template-columns: repeat(1,1fr);
			// }
		}

		// .flats {
		// 	margin: 60px 0;

		// 	p {
		// 		margin-bottom: 18px; 
 		// 	}

		// 	img {
		// 		max-width: 200px;
		// 		margin:0 auto;
		// 		margin-bottom: 30px;
		// 	}
		// }

		// &_item{
		// 	color:$grey;
		// 	margin-bottom: 20px;

		// 	h3{
		// 		font-weight: 400;
		// 		font-size: 20px;
		// 		margin-bottom: 10px;
		// 	}

		// 	p{
		// 		margin-bottom: 5px;
		// 	}

		// 	a{
		// 		margin-bottom: 10px;

		// 		svg {
		// 			fill:$grey;
		// 			height: 16px;
		// 			width: auto;
		// 			margin-right: 8px; 
		// 		}

		// 		&.link-bold {
		// 			font-weight: 700;
		// 		}
		// 	}
		// }
	}

	a {
		color:$grey;
		transition: all 0.2s linear;
		
		&:visited {
			text-decoration: none;
			color:$grey;
		}
		&:hover {
			color:$black;
		}
	}
	
	.copyright {
		text-align: center;
		font-size: 14px;
		background: $grey;
		padding: 20px;

		a {
			color:$cream-dark;
		}
	}

	@include breakpoint(phablet) {

		&_socials{
			padding: 40px;

			.column {
				text-align: left;

				h2 {
					width: fit-content;
				
					img {
						margin: 0 0 30px 0;
					}				
				}

				&.commercial {
					display: grid;
					grid-template-columns: repeat(2,1fr);
					gap: 30px;
				}
			}

			article {
				margin: 0;
			}
		}

	}

	@include breakpoint(tablet) {

		&_socials {
			padding: 60px;
			display: grid;
			grid-template-columns: auto 260px; 
			gap: 200px;
 
			.column { 				
 
				.menu-footer {
					font-size: 20px;
				}

				.items-social { 
					
					li {
						margin: 0;

						svg {
							width: auto; 
							height: 26px;
							margin: 0 22px 0 0;
						}
					}					

					+ .menu-footer {
						font-size: 16px;
					}
				}
			}
		}
	}

	@include breakpoint(desktop) {

		&_socials {
			gap: 30vw;
		}		    
	}
}