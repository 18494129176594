.live{
    background: $white;

    &_tagline {
        padding:0 20px 0px;
        color: $grey;
        text-align:center;

        h2 {
            font-weight: 400;
            font-size: 24px;
            font-family: 'Lora';
            margin-bottom: 10px;

            span {
                font-style: italic;
                font-weight: bold;
            }
        }
        p{
            margin-bottom: 20px;
            font-family: 'Fira Sans';
        }
    }

    &_list{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        align-items: center;
        justify-items: center;

        li{
            //max-height: 75px;
            overflow: hidden;
            //width: 25vw;
            height: 100%;

            img{
                height: 100%;
            }
        }
    }

    @include breakpoint(mobilelandscape) {


    }

    @include breakpoint(phablet) {

        &_tagline{
            padding:0 40px 0px;

            h2{
                font-size: 32px;
            }
            p{
                margin-bottom: 30px;
            }
        }

    }

    @include breakpoint(tablet) {

        &_tagline{
            padding: 0px 60px 0px;
            max-width: 1180px;
            margin:0 auto;

            p{
                margin-bottom: 40px;
            }
        }

        &_list{
            grid-template-columns: repeat(7,1fr);
        }

    }

    @include breakpoint(laptop) {

        &_tagline{
            padding:0 80px 0px;

            h2{
                font-size: 36px;
            }

            p{
                font-size: 20px;
            }
        }

        &_list{
            
            li{
                img{
                    transition: all .3s ease-in-out;
                    -webkit-filter: brightness(90%);

                    &:hover{
                        scale: 1.1;
                        -webkit-filter: brightness(100%);
                    }
                }
            }
        }

    }

    @include breakpoint(desktop) {

        &_tagline{
            //max-width: 1440px;
        }
    }
    
    @include breakpoint(bigdesktop) {

        &_tagline{
            padding: 0 100px 0px;

            h2{
                font-size: 36px;
            }
            p{
                font-size: 20px;
            }
        }

    }
}