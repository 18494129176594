.promotion {
    padding: 120px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;

    &.iguazu{
        background: $cream;
        color: $grey;

        .promotion_list{
            li{
                overflow: hidden;
                display: flex;
                flex-direction: column;

                p{
                    margin-bottom: 20px;
                }
            }
        }

        .border {
            padding: 26px 0;
            border: 2px solid $iguazu;
            border-radius: 0 0 10px 10px;
            border-top: none;
            flex: auto;
        }
    
        a.button{
            background-color: $iguazu;
            border: 2px solid $iguazu;
            font-weight: 400;
    
            span{
                color:$white;
                font-weight: 400;
                padding:.8em 1em;
            }
    
            &::before{
                background-color: $cream;
                color:$iguazu;
                font-weight: 400;
                padding:.8em 1em;
    
            }
        }
    }

    h2{
        font-weight: 400;
        font-size: 24px;
        font-family: 'Lora';
        margin-bottom: 10px;
        color: $grey;
        text-align: center;
    }
    h3{
        font-size: 20px;
        font-weight: 400;
        color: $grey;
        text-align: center;
        margin:20px 0;
        font-family: 'Lora';
    }
    p {
        margin-bottom: 20px;
        color: $grey;
        text-align: center;
        font-family: 'Fira Sans';
    }

    &_list {
        display: grid;
        grid-template-columns: repeat(1,1fr);

        li {
            background: $cream-dark;
            border-radius: 10px;
            margin-bottom: 30px;
            padding-bottom: 30px;
            overflow: hidden;

            p {
                color: $grey;
                text-align: center;
                margin-bottom: 20px;
                padding:0 10px;
            }
        }

        .button {
            width: 170px;
            margin: 0 auto !important;
            border-color: $grey !important;
            background-color: $grey !important;;
            
            span {
                color: $white !important;
            }
            
            &:before {
                background-color: $cream-dark !important;
                color: $grey !important;;
            }
        }
    }

    @include breakpoint(mobilelandscape) {

        &_list{
            grid-template-columns: repeat(3,1fr);
            column-gap: 10px;
        }

    }

    @include breakpoint(phablet) {
        padding: 160px 40px 40px;

        h2 {
            font-size: 32px;
        }
        p {
            margin-bottom: 30px;
        }

        &_list {
            grid-template-columns: repeat(3,1fr);
            column-gap: 20px;
        }
    }

    @include breakpoint(tablet) {
        padding:180px 60px 60px;

        h3 {
            font-size: 28px;
        }

        p {
            margin-bottom: 40px;
        }

        &_list {
            max-width: 1030px; 

            li {
                p {
                    padding: 0 20px;
                }
            }
        }

        .iguazu & {
            margin-top: -50px;
        }
    }

    @include breakpoint(laptop) {
        padding: 180px 80px 80px;
 
        h2{
            font-size: 36px;
        }

        &_list {

            li {
                p {
                    padding: 0 30px;
                    margin-bottom: 42px; 
                }
            }
        }
    }

    @include breakpoint(bigdesktop) {
        padding: 180px 100px 100px;
    }
}