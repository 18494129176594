.events {
    padding: 120px 20px 20px;
    background: none;
    color: $grey;

    .items {
        margin: 42px auto 0; 

        li {
            margin-bottom: 20px; 
        }
    }

    &.home {
        padding-top: 40px; 
        display: block;

        h2 {
            max-width: 460px;

            span {
                color: $iguazu;

                .chapelco & {
                    color: $chapelco;
                }
                .recoleta & {
                    color: $recoleta;
                }
                .esmeralda & {
                    color: $esmeralda;
                }
            }
        }

        .image {
            margin: 26px 0 32px 0;
        }

        .swiper {
            margin: 26px 0 32px 0;
            padding-bottom: 60px;
    
            &-pagination{

                &-bullet{
                    background:$grey;
                    width: 18px;
                    height:18px;
                } 
            }
        }

        h3 {
            font-size: 24px;
            margin-bottom: 18px;
        }
        
        a.button {
            margin: 28px auto 0;
            border-color: $cream-dark;
            background: $cream-dark;
            // color: $black;
            
            &:before {
                color: $cream-dark;
                background-color: $grey;
            }
        }

        header {
            text-align: center;
            padding: 0 20px;
        }

        & + .promotion {
            padding-top: 40px;
        }

        + .we.events.home {
            background-color: $cream-dark;

            a.button {
                margin: 28px auto 0;
                border-color: $white;
                background: $white;
                // color: $black;
                
                &:before {
                    color: $white;
                    background-color: $grey;
                }
            }            
        }
    }

    @include breakpoint(phablet) {
        padding: 160px 40px 40px; 
        
        .items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            margin: 42px -40px;

            li {
                margin: 0px;
            }
        }        

        &.home {
            display: flex;
            
            .event {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 36px;
            }

            header {
                padding: 20px 0 0 20px;
    
                p {
                    max-width: none;
                }
            }

            .slide-events-home, .image {
                flex: 1 0 55%;
                width: 55%;
                margin: 0;
            }
        }
    }
    @include breakpoint(tablet) {
        padding:180px 60px 60px;
        
        .items {
            margin: 42px -60px;
        }        

        &.home {
            padding: 60px;

            h2 {
                font-size: 36px;
                margin-bottom: 22px;

                .iguazu & {
                    font-size: 32px;
                    margin: 0;
                }
            }            
            
            .event {
                max-width: 1030px;
                margin: 42px auto 0;
                align-items: center;
            }
        }
    }
    @include breakpoint(laptop) {
        padding: 180px 80px 80px;
        
        .items {
            margin: 62px -80px;
        }

        &.home {
            padding: 80px;

            header {
                padding: 0 0 0 40px;
            }
        }        
    }

    @include breakpoint(bigdesktop) {
        padding: 180px 100px 100px;
        
        .items {
            margin: 82px -100px;
        }
    }   
}

.events-detail {
    padding: 40px 20px;
    color: $grey;

    h3 {
        margin-bottom: 22px;
    }

    h4 {
        font-size: 18px;
        text-align: center;
    }

    p {
        margin-bottom: 12px;
    }

    .events-gallery {
        margin-bottom: 48px;
        position: relative;

        p {
            font-size: 14px;
            font-style: italic;
            text-align: right;
            padding-top: 6px;
        }
    }

    .float-icon {
        
        &:before{
            content:url('../img/icon-exp-20.svg');
            position: absolute;
            top: -20px;
            right: 10px;
            width: 80px;
            z-index: 3;
        }
    }
    
    &.chapelco {
        background: $cream-dark;
        position: relative;
        
        .float-icon {
         
            &:before{
                content:url('../img/icon-exp-18.svg');
                top: -20px;
                right: auto;
                left: 10px;
            }   
        }     
    }
    
    &.recoleta {
        
        .float-icon {
         
            &:before{
                content:url('../img/icon-exp-17.svg');
            }   
        }     
    }

    @include breakpoint(tablet) {
        padding: 60px;
        
        .top {
            display: flex;
            justify-content: space-between;
        }
        .events-content {
            padding-right: 42px;
        }
        .events-gallery {
            margin: 0;
            margin-top: 48px;
            width: 50%;
        }
        .gallery-bottom {

            .events-gallery {
                margin: 0;
            }
        }
        .float-icon {
            
            &:before{
                top: -30px;
                right: -30px;
                width: 100px; 
            }
        }
        
        &.chapelco {

            .events-content {
                padding: 0 0 0 42px;
                order: 2;
                text-align: right;
            }
        
            .float-icon {
         
                &:before{
                    top: -30px;
                    left: -30px;
                }   
            }            
        }        
        
        &.recoleta {
            
            .float-icon { 
            
                &:before{
                    top: -30px;
                    right: -30px;
                    width: 100px; 
                }
            }                
        }        
    }
    @include breakpoint(laptop) {
        padding: 100px;
        max-width: 1280px;
        margin: 0 auto;

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            background: $cream-dark;
            position: absolute;
            top: 0;
            left: -100%;
        }
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            background: $cream-dark;
            position: absolute;
            top: 0;
            right: -100%;
        }
    }
}
 
.floors {

    li {
        margin-bottom: 36px;
    }

    @include breakpoint(phablet) {
        display: flex;
        justify-content: center;
        
        li {
            width: 50%;
            max-width: 450px;
            margin: 0;
        }
    }
    @include breakpoint(tablet) {
        margin: 60px 0;
    }
}

.capacities {
    margin-bottom: 42px;
    
    h3 {
        font-size: 26px;
        color: $grey;
    }
    
    img {
        width: 46px;
        height: auto;
        margin: 0 auto;
    }
    .wrapper {
        overflow-x: scroll;
        overflow-y: hidden;

        + p {
            margin-top: 22px;
        }
    }

    table { 
		width: 100%; 
		border-collapse: collapse; 
	
		/* Zebra striping */
		tr:nth-of-type(odd) { 
			background: #eee; 
		}
		th { 
			background: $iguazu;
			color: white; 
			vertical-align: middle;
			
			&:first-child {
				text-align: left; 
			}

            .chapelco & {
                background-color: $chapelco;
            }
            .recoleta & {
                background-color: $recoleta;
            }
		}
		td, th {
			padding: 12px; 
			border: 1px solid #ccc; 
			white-space: nowrap;
			text-align: center;
		}
		td {
            
			&:first-child {
				text-align: left; 
			}
		}
    }   
    
    @include breakpoint(tablet) {
        max-width: 900px;
        margin: 0 auto 82px;

        h3 {
            text-align: center;
        }

        .wrapper {
            overflow-x: auto;
        }
    }
}

.events-content {
    margin-bottom: 40px;
}

.swiper-container {
    position: relative;
    overflow: hidden;

    .swiper-button-next, .swiper-button-prev {
        color: #000;
        z-index: 5;
    }
}

.gallery-bottom {

    h3 {
        font-size: 26px;
    }

    @include breakpoint(tablet) {
    
        h3 {
            text-align: center;
            margin-bottom: 32px;
        }

        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .events-gallery, .events-content {
            flex: 50%;
            width: 50%;
        }

        .events-content {
            padding: 0 0 0 42px;
            margin: 0;
            text-align: right;
        }
    }

    @include breakpoint(laptop) {
        max-width: 1024px;
        margin: 0 auto;
    }
}
  
.promotion-hotel {
    background-color: $cream-dark;
    padding: 40px 20px;
    color: $grey;
    position: relative;
    
    .float-icon {
        position: absolute;
        top: 73px;
        right: 32px;
        width: 65px; 
        height: 65px; 
        z-index: 6;
    }
    h2 {
        margin-bottom: 22px;
    } 
    h3 {
        font-size: 24px;
        margin-bottom: 16px;
    }
    p {
        margin-bottom: 2px;
    }
    a {
        color: $grey;
        border-bottom: 1px transparent solid;
        transition: .3s all ease-out;

        &:hover {
            border-bottom-color: $grey;
        }
    }
    .picture {
        margin-bottom: 36px;
    }
    .button {
        margin: 28px 0 0;
        border-color: $iguazu;
        background: $iguazu;
        
        &:before {
            color: $iguazu;
        }
    }
    .space-wrapper {
        overflow: hidden;
        position: relative;  
    }
    .swiper-button-next, .swiper-button-prev {
        display: none;
    }
    &.chapelco {
        background: white;
    
        .button {
            border-color: $chapelco;
            background: $chapelco;
            
            &:before {
                color: $chapelco;
            }
        }         
    }
    &.recoleta {
    
        .button {
            border-color: $recoleta;
            background: $recoleta;
            
            &:before {
                color: $recoleta;
            }
        }         
    }
    &.esmeralda {
        background: white;

        .button {
            border-color: $esmeralda;
            background: $esmeralda;
            
            &:before {
                color: $esmeralda;
            }
        }        
    }

    @include breakpoint(phablet) {
        padding: 60px 40px;
        display: flex;
        flex-direction: column;
        
        .float-icon {
            top: 86px;
            right: 40px;
            width: 105px;
            height: 105px;
        }
        h2 {
            margin: 0 auto 22px;
            transform: translate(50%);
        }
        .space-wrapper {
            padding: 0 40px;

            &:before {
                content: "";
                width: 40px;
                height: 100%;
                background-color: $cream-dark;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
            }
            &:after {
                content: "";
                width: 40px;
                height: 100%;
                background-color: $cream-dark;
                position: absolute;
                top: 0;
                right: 0;  
                z-index: 2;
            }
        }
        .swiper-container {
            overflow: visible;
        }
        .swiper-slide {
            display: flex;
            align-items: center; 
            justify-content: space-between;
            
            .picture {
                width: 50%;
                order: 2;
                margin: 0;
            }
            .content {
                max-width: 380px;
                width: 50%;
                padding-right: 30px;
            }
        }
        .swiper-button-next {
            right: -42px;
            display: block;
        }
        .swiper-button-prev {
            left: -40px;
            display: block;
        } 
        &.chapelco, &.esmeralda {
        
            .float-icon {
                right: auto;
                left: 40px;  
            }
            h2 { 
                transform: translate(-50%);
            }
            .content {
                text-align: right;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            } 
            .space-wrapper {
    
                &:before {
                    background-color: $white;
                }
                &:after {
                    background-color: $white;
                }
            }
            .swiper-slide {
    
                .picture {
                    order: 1;
                }
                .content {
                    order: 2;
                    padding-right: 0px;
                    padding-left: 30px;
                }
            }            
        }
    }
    @include breakpoint(laptop) {
 
        .space-wrapper {
            padding: 0 80px;
            max-width: 1160px;
            margin: 0 auto; 

            &:before {
                width: 80px;
            }  
            &:after {
                width: 80px;
            }
        }
        .swiper-button-next {
            right: -82px;
        }
        .swiper-button-prev {
            left: -80px;
        }
        .float-icon {
            transform: translate(560px);
            right: 50%;
        }
        &.chapelco, &.esmeralda {
            
            .float-icon {
                right: auto;
                left: 50%;
                transform: translate(-560px);
            }
        }
    }
    @include breakpoint(desktop) {
        padding: 60px 40px 80px;
    }
}

.subscribe {
    padding: 40px 20px;
    background-color: $cream-dark;
    color: $grey;    
    text-align: center;

    h2 {
        margin-bottom: 12px;
    }
    form {
        margin-top: 32px;
    }
    .btn {
        width: fit-content;
        color: #fff;
        background-color: $grey;
        padding: 10px 20px;
        border: 2px $grey solid;
        border-radius: 10px;
            // font-weight: 400;
        margin: 0 auto;
        cursor: pointer;
        transition: all .3s ease;
    }   

    @include breakpoint(phablet) {

        h2 {

            span {
                display: block;
            }
        }
        form {
            max-width: 420px;
            margin: 32px auto 0;            
            display: flex;
            justify-content: space-between;
            align-items: center;

            .form-group.control-text {
                margin: 0 20px 0 0;
                flex: 0 1 auto; 
                width: 100%;
            }
            input {
                margin: 0;
            }
            .btn { 
                width: 240px;

                &:hover {
                    background-color: $white;
                    color: $grey;
                }
            }
        }
    }
    @include breakpoint(tablet) {
        padding:60px;
    } 
    @include breakpoint(laptop) {
        padding:80px;

        h2 {
            font-size: 32px;
        }
        form {
            max-width: 540px;
        }
    }    
}