.gallery{
    margin: 40px 0;
    color: $grey;

    h2 {
        text-align: center;
        margin: 0 30px 30px;

        strong {
            font-style: italic;
        }
    }

    header {
        margin: 0 30px 42px;
        text-align: center;

        p {
            margin-bottom: 22px;
        }
    }

    a.button {
        margin: 0 auto;
        background-color: $cream;
        border: 2px solid $cream;
        width: 152px;

        span{
            color:$grey;
            font-weight: 500;
        }

        &::before{
            background-color: $grey;
            color:$white;
            font-weight: 500;
        }
    }    

    .swiper-container {
        overflow: hidden;
        position: relative;  
    }

    .swiper-button {
        background: none;
        left: 8%;
        
        &:after{ 
            content: none;
        }

        .arrow {
            display: block;
            transform: rotate(-.75turn);
            height: 28px;
        }

        &-next {
            left: auto;
            right: 8%;

            .arrow { 
                transform: rotate(.75turn);
            }
        }
    } 

    @include breakpoint(tablet) {

        .swiper-slide {
            width: 60%;
            opacity: .4;
            transition: .3s ease-in-out;
             
            &.half { 
                width: 30%;
            }

            &-active {
                opacity: 1;
            }
        }

        .arrow {

            polyline {
                stroke: $black;
            }
        }  

        &.loiflats {
            display: grid;
            grid-template-columns: 70vw 30vw;
            padding: 60px 0;
            margin: 0;
        }

        h2 {
            grid-column: 1 / 3;
        }

        header {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            margin: 0;
            padding: 0 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .gallery_lflats {
            grid-column: 1 / 2;

            .swiper-slide {
                // width: 60%;
                opacity: 1;
            }
        }
    }

    @include breakpoint(laptop) {
        margin:100px 0;

        .swiper-button {
            width: 36px;
        }

        &.loiflats {
            padding: 80px 0 90px;
        }        

        h2 {
            font-size: 36px;
            margin-bottom: 42px;
        }

        header {
            padding: 0 160px 0 40px;
            background: url('../img/gallery-lflats-03.jpg') no-repeat;
            background-size: cover;
            background-position: calc(60vh - 120px) center;
        }
    }
}