.rooms {
    background: $white;
    padding: 40px 0px 0px;

    h2 {
        color: $grey;
        font-weight: 400;
        font-family: 'Lora';
        text-align: center;
        position: relative;
        z-index: 3;
        
        span {
            font-style: italic; 
            font-weight: bold;
        }
        
        &:before{
            content:url('../img/icon-exp-20.svg');
            position: absolute;
            top:16px;
            right:0;
            width: 65px;
        }
    }

    h3 {
        font-size:22px;
        color:$iguazu;
    }

    .swiper-container{
        overflow: hidden;
        position: relative;

        .swiper-button-prev , .swiper-button-next{
            color:$black;

            &::after{
                font-size:30px;
            }
        }

        .swiper-button-next{
            right:0;
        }
        .swiper-button-prev{
            left: 0;
        }
    }

    &_item{
        background-color: $cream-dark;

        &_picture{
            position: relative;
            margin-bottom: 10px;
        }

        &_description{
            padding:0 20px 20px;

            h3{
                color:$iguazu;
                font-size: 32px;
                margin-bottom: 10px;
            }

            h4{
                font-size: 16px;
                color:$grey;
                font-weight: 600;
                margin-bottom: 20px;

                &.benefits{
                    color:$iguazu;
                }
            }

            p{
                color:$grey;
            }

            &_size{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin:10px 0;

                li{
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0; 
                    }

                    img{
                        width: 40px;
                        margin-right: 5px;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 300;
                        font-style: italic;
                    }
                }
            }

            .line{
                border-bottom: 2px solid $iguazu;
                width: 90%;
                margin: 0 auto 10px 0;
            }

            &_amenities{
                display: grid;
                grid-template-columns: repeat(1,1fr);
                margin-bottom: 20px;

                li{
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    justify-content: flex-start;

                    img{
                        width: 40px;
                        margin-right: 20px;
                    }

                    p{
                        font-size: 14px;
                        font-weight: 300;
                        font-style: italic;
                    }
                }
            }

            .button{
                color:$white;
                background-color: $iguazu;
                border-color: $iguazu;
                border-radius: 20px;
                margin:0 auto 0 0;

                &:before{
                    color:$iguazu;
                }
            }
        }
    }

	.gallery-thumbs {
		height: auto;
		box-sizing: border-box;
        padding-top: 20px;
        margin-bottom: 20px;

        .swiper-button-prev , .swiper-button-next{
            display: none;
        }

        .swiper-wrapper{
            .swiper-slide{
                figure{
                    &.recomended{
                        &::before{
                            position: absolute;
                            content: url(../img/recomendado.svg);
                            top: -30px;
                            left: 0;
                            transform: scale(.7);
                            width: 100%;
                        }
                    }

                    img{
                        margin-bottom: 5px;
                    }
                    figcaption{
                        color:$grey;
                        font-size: 18px;
                    }
                }
            }
        }
	}
	.gallery-thumbs .swiper-slide {
		background-size: cover;
     	background-position: center;
		opacity: 0.4;
        cursor: pointer;
        
        img{
            padding:5px;
            border: 1px transparent solid;
        }

        figcaption{
            padding-left:6px;
        }

        &:hover{
            img{
                border-color: $iguazu;
            } 
        }

	}
	.gallery-thumbs .swiper-slide-thumb-active {
		opacity: 1;

        img{
            border-color: $iguazu;
        }
	}

    .chapelco &{

        h2 {

            &:before{
                content:url('../img/icon-exp-18.svg');
            }
            
        }
        h3 {
            color:$chapelco;
        }

        &_item{
            border-color: $chapelco;
        }

        .gallery-thumbs .swiper-slide {
            &:hover{
               img{
                border-color: $chapelco;
               } 
            }
        }
        .gallery-thumbs .swiper-slide-thumb-active {
            img{
                border-color: $chapelco;
            }
        }
        .rooms_item_description{
            .button{
                background-color: $chapelco;
                border-color: $chapelco;
                &:before{
                    color:$chapelco;
                }
            }
            .line{
                border-bottom: 2px solid $chapelco;
            }
        }
    }

    .esmeralda &{

        h2 {
            &:before{
                content:url('../img/icon-exp-19.svg');
            }
        }
        
        h3{
            color:$esmeralda;
        }

        &_item {
            border-color: $esmeralda;
        }

        .gallery-thumbs .swiper-slide {
            &:hover{
               img{
                border-color: $esmeralda;
               } 
            }
        }
        .gallery-thumbs .swiper-slide-thumb-active {
            img{
                border-color: $esmeralda;
            }
        }
        .rooms_item_description{
            .button{
                background-color: $esmeralda;
                border-color: $esmeralda;
                &:before{
                    color:$esmeralda;
                }
            }
            .line{
                border-bottom: 2px solid $esmeralda;
            }
        }
    }

    .recoleta &{

        h2 {
            &:before{
                content:url('../img/icon-exp-17.svg');
            }
        }

        h3{
            color: $recoleta;
        }
        
        &_item{
            border-color: $recoleta;
        }

        .gallery-thumbs .swiper-slide {
            &:hover{
               img{
                border-color: $recoleta;
               } 
            }
        }
        .gallery-thumbs .swiper-slide-thumb-active {
            img{
                border-color: $recoleta;
            }
        }
        .rooms_item_description{
            .button{
                background-color: $recoleta;
                border-color: $recoleta;
                &:before{
                    color:$recoleta;
                }
            }
            .line{
                border-bottom: 2px solid $recoleta;
            }
        }
    }    

    @include breakpoint(mobilelandscape) {

        &_item{

            &_description{

                &_amenities{
                    grid-template-columns: repeat(2,1fr);
                    column-gap: 10px;
                }
            }
        }

        .gallery-thumbs{
            .swiper-wrapper{
                .swiper-slide{
                    figure{
                        &.recomended{
                            &::before{
                                left: 20%;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @include breakpoint(phablet) {
        padding:40px 0px 0px;

        h2{
            font-size: 32px;

            &:before {
                right: 25%;
                width: 85px;
            }
        }

        .chapelco &{
            h2 {
                &:before{
                    right: 25%;
                    width: 85px;
                }
            }
        }

        .esmeralda &{
            h2 {
                &:before{
                    right: 25%;
                    width: 85px;
                }
            }
        }

        .recoleta &{
            h2 {
                &:before{
                    right: 25%;
                    width: 85px;
                }
            }
        }

        &_gallery_thumbs{
            padding:0 20px;
        }

        .gallery-thumbs{
            padding-top:30px;
            .swiper-wrapper{
                .swiper-slide{
                    figure{
                        &.recomended{
                            &::before{
                                transform: scale(.6);
                            }
                        }
                    }
                }
            }
        }

        &_gallery_slide{
            .swiper-wrapper{
                .swiper-slide{
                    width: 100% !important;
                }
            }
        }

        &_item{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 20px;
            padding:20px 20px 40px;

            &_picture{
                grid-column: 2 / 3;
                grid-row: 1 / 1;
                height: fit-content;
            }

            &_description{
                padding: 0;
                grid-column: 1 / 2;
                grid-row: 1 / 1;

                &_amenities{
                    grid-template-columns: repeat(2,1fr);
                    column-gap: 20px;
                }
            }
        }
    
    }

    @include breakpoint(tablet) {
        padding: 60px 0 0;

        h3 {
            font-size: 26px;
        }

        &_item{
            column-gap: 40px;
            padding:40px 60px 40px;

            &_picture{

                h3{
                    font-size: 42px;
                }
            }

            &_description{

                h3{
                    font-size: 32px;
                }
            }
        }

		.gallery-thumbs {
            padding: 30px 60px 0;
            margin-bottom: 40px;

            .swiper-wrapper{

                .swiper-slide{

                    figure{
                        img{
                            margin-bottom: 10px;
                        }
                        figcaption{
                            color:$grey;
                            font-size: 20px;
                        }
                    }
                }
            }
		}
    }

    @include breakpoint(laptop) {
        padding: 60px 0 0;

        h2{
            font-size: 36px;
        }

        figure {
            display: block;
            
            &:before {
                transition: transform .2s ease-in;
                transition: opacity .3s ease-in;
                font-size: 28px;
                transform: translate(-100%,0);
            }    
            
            &:hover {
                cursor: pointer;

                &:before {
                    transform: translate(0);
                    opacity: 1; 
                }
            }
        }

		.gallery-thumbs {
            margin-bottom: 40px;

            .swiper-wrapper{

                .swiper-slide{
                    figure{
                        figcaption{
                            font-size: 20px;
                        }
                        &.recomended{
                            &::before{
                                left: 10%;
                            }
                        }
                    }
                }

            } 
		}

        &_item{
            padding:60px;

            &_description{
                h3{
                    font-size: 36px;
                }
            }
        }
    }

    @include breakpoint(bigdesktop) {
        padding: 80px 0 0;

        h2{
            font-size: 36px;
        }

        .gallery-thumbs {
            margin-bottom: 40px;
            padding: 30px 80px;
		}

        &_item{
            padding:80px;

            &_description{

                h4{
                    font-size: 18px;
                }
                p{
                    font-size: 18px;
                }

                .line{
                    margin: 0 auto 15px 0;
                }

                &_size{
                    margin:15px 0;

                    li{
                        p{
                            font-size: 16px;
                        }
                    }
                }
                &_amenities{
                    li{
                        p{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}