.hotel {

	&.reserva {
		padding-top: 70px;
	}

    &_header {
		background: $cream;
		padding: 86px 30px;
		text-align: center;
		
		iframe {
			min-height: 1200px;
			max-width: 960px;
			margin: 0 auto;
			display: block;
		}
    }

    h2 {
        font-size: 30px;
		color: $white;
    }
	
	&.chapelco {

		.hotel_header {
			background-color: $chapelco;
		}
	}
	
	&.esmeralda {

		.hotel_header {
			background-color: $esmeralda;
		}
	}
	
	&.iguazu {

		.hotel_header {
			background-color: $iguazu;
		}
	}
	
	&.recoleta {

		.hotel_header {
			background-color: $recoleta;
		}
	}
}