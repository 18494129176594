.experience {
    padding:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $cream;

    h2{
        font-weight: 400;
        font-size: 24px;
        font-family: 'Lora';
        margin-bottom: 10px;
        color: $grey;
        text-align: center;

        span {
            font-style: italic;
            font-weight: bold;
        }
    }
    p {
        color: $grey;
        text-align: center;
        font-family: 'Fira Sans';

        &:nth-child(3){
            margin-bottom: 32px;
        }
    }

    &_list {

        li {
            margin-bottom: 20px;

        }
    }
    
    figure {
        position: relative;

        img {
            overflow: hidden;
            max-height: 100%;
            
            &.icono {
                position: absolute;
                top: 5%;
                right: 5%;
                width: 23%;
                max-height: unset;
                z-index: 1;
            }
        }
        
        figcaption {
            font-weight: 400;
            font-size: 24px;
            font-family: 'Lora';
            color: $grey;
            text-align: center;
            position: relative;
            background-color: $cream;
            z-index: 2;
            padding-top: 12px;
        }

        &:before {
            content: '';
            font-family: $font-base;
            font-size: 16px;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding: 30px 30px 66px;
            width: 100%;
            height: 100%;
            position: absolute;
            transform: translate(0,0);
            z-index: 1; 
            pointer-events: none;
        }
        
        &.iguazu {
            
            &:before {
                background: rgba(0,122,92,.5);
            }            
        }

        &.chapelco {

            &:before {
                background: rgba(209,30,28,.5);
            }            
        }

        &.recoleta {

            &:before {
                background: rgba(201,154,131,.5);
            }            
        }

        &.esmeralda {

            &:before {
                background: rgba(0,96,153,.5);
            }            
        }                
    }

    @include breakpoint(mobilelandscape) {

        &_list {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 20px;
        }
    }

    @include breakpoint(phablet) {
        padding: 40px;

        h2 {
            font-size: 32px;
        }

        p{

            &:nth-child(3){
                margin-bottom: 42px;
            }
        }

        &_list {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 20px;
        }
        figure {

            figcaption {
                padding: 20px 0;
            }
        }
    }

    @include breakpoint(tablet) {
        padding: 60px;

        p {
            max-width: 1180px;
        }

        &_list{
            max-width: 1180px;
        }

        figure {
    
            figcaption {
                text-align: left;
                // font-size: 28px;
            }
            &:before {
                justify-content: flex-start;
                padding-bottom: 96px;
            }
        }
    }

    @include breakpoint(laptop) {
        padding: 80px;

        h2  {
            font-size: 36px;
        }

        p {
            font-size: 20px;

            &:nth-child(3){
                margin-bottom: 62px;
            }
        }
        figure {
            overflow: hidden;

            &:before {
                transition: transform .3s ease-in;
                transition: opacity .3s ease-in;
                font-size: 18px;
                transform: translate(-100%,0);
                opacity: 0;
            }    
            
            &:hover {
                cursor: pointer;

                &:before {
                    transform: translate(0);
                    opacity: 1; 
                }
            }        
        }
    }

    @include breakpoint(bigdesktop) {
        padding: 100px;

    } 
}