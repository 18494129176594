.services{
    background: $white;
    padding: 0 20px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.iguazu{
        background: $cream;

        .services_list{

            li{
                figure{
                    figcaption{
                        color:$grey;             
                        line-height: 1.4;
                    }
                }
            }
        }
    }

    h2{
        font-weight: 400;
        font-size: 24px;
        font-family: 'Lora';
        margin-bottom: 30px;
        color: $grey;
        text-align: center;
    }
    
    &_list{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
        width: 80%;

        li{

            figure {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    margin-bottom: 16px;
                    width: 90px;
                }

                figcaption{
                    text-align: center;             
                }
            }
        }

    }

    @include breakpoint(mobilelandscape) {

        &_list{
            grid-template-columns: repeat(3,1fr);
        }

    }

    @include breakpoint(phablet) {
        padding:0 40px 40px;

        h2{
            font-size: 32px;
            margin-bottom: 30px;
        }

        &_list{
            grid-template-columns: repeat(3,1fr);
        }

        .recoleta &{
            &_list{
                grid-template-columns: repeat(4,1fr);
            }
        }

        .esmeralda &{

            &_list{
                grid-template-columns: repeat(6,1fr);

                li{
                    &:nth-child(1){
                        grid-column: 1/3;
                    }
                    &:nth-child(2){
                        grid-column: 3/5;
                    }
                    &:nth-child(3){
                        grid-column: 5/7;
                    }
                    &:nth-child(4){
                        grid-column: 2/4;
                    }
                    &:nth-child(5){
                        grid-column: 4/6;
                    }
                }
            }
        }

    }

    @include breakpoint(tablet) {
        padding: 0 60px 60px;

        &_list{
            max-width: 80%;
            grid-template-columns: repeat(6,1fr);

            img{
                max-width: 80px;
            }
        }

        .iguazu & , .chapelco &{

            .services_list{
                grid-template-columns: repeat(5,1fr);
            }
        }

        .esmeralda &{

            &_list{
                grid-template-columns: repeat(6,1fr);

                li{
                    &:nth-child(1){
                        grid-column: 2/3;
                    }
                    &:nth-child(3){
                        grid-column: 5/6;
                    }
                }
            }
        }

    }

    @include breakpoint(laptop) {
        padding: 0 80px 80px;

        h2{
            font-size: 36px;

        }

    }

    @include breakpoint(desktop) {

        &_list{
            max-width: 1440px;
        }
    }

    @include breakpoint(bigdesktop) {
        padding: 0 100px 100px;

        h2{
            font-size: 36px;
        }

    }

}