.gastro {
    padding: 40px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: bottom center; 

    h2 {
        font-weight: 400;
        font-size: 24px;
        font-family: 'Lora';
        margin-bottom: 10px;
        color: $grey;
        text-align: center;

        span {
            font-style: italic;
        }
    }

    h3{
        font-weight: 400;
        font-size: 20px;
        font-family: 'Lora';
        margin-bottom: 5px;
        color: $grey;
        text-align: center;
    }

    p {
        color: $grey;
        text-align: center;
        font-family: 'Fira Sans';
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 20px;
        }

        + h3 {
            margin-top: 36px;
        }
    }

    a {
        color: $grey;

        svg {
            height: 18px;
        }

        &.icon {
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;
            margin-top: 12px;
        }

        &:hover {
            color: $black;
        }
    }

    .button {
        margin-top: 28px;
    }

    &_list {
        margin-top: 42px;

        li {
            margin-bottom: 20px;
        }
    }

    .recoleta &{
        background-color: $cream-dark;
        background-image: url('../img/bg-food-recoleta.jpg'); 
        background-position: bottom right; 
        background-size: auto;  
    }    

    @include breakpoint(mobilelandscape) {

        &_list {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 20px;
        }
    }

    @include breakpoint(phablet) {
        padding: 40px;

        .recoleta &{
            background-size: 50%;
        }

        h2 {
            font-size: 32px;
        }

        h3{
            font-size: 24px;
        }

        p{
            &:last-child{
                margin-bottom: 42px;
            }
        }

        &_list{
            display: grid;
            grid-template-columns: repeat(5,1fr);
            grid-template-rows: repeat(5,1fr);
            gap:10px;

            li{
                margin: 0;

                &:nth-child(1){
                    grid-column: 1 / 3;
                    grid-row: 1 / 3;
                }
                &:nth-child(2){
                    grid-column: 3 / 5;
                    grid-row: 1 / 3;
                }
                &:nth-child(3){
                    grid-column: 5 / 6;
                    grid-row: 1 / 4;
                }
                &:nth-child(4){
                    grid-column: 1 / 2;
                    grid-row: 3 / 6;
                }
                &:nth-child(5){
                    grid-column: 2 / 5;
                    grid-row: 3 / 6;
                }
                &:nth-child(6){
                    grid-column: 5 / 6;
                    grid-row: 4 / 6;
                }

                picture{
                    height: 100%;
                    display: flex;

                    img{
                        object-fit: cover;
                    }
                }
            }
        }
    }

    @include breakpoint(tablet) {
        padding: 60px 60px 0;

        .recoleta &{
            padding: 80px 60px;
        }

        h2{
            // max-width: 60%;
            font-size:27px;
        }

        h3{
            font-size: 22px;
        }

        p {
            max-width: 70%;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        &_list{
            max-width: 1180px;
            margin-top: 62px;
        }
        
        .esmeralda & {
            padding-bottom: 60px;

            .gastro_list {
                grid-template-columns: repeat(3,1fr);
                grid-template-rows: unset;
                position: relative;

                li {
                    grid-column: unset;
                    grid-row: unset;
                    max-width: unset;
                    margin:0;
                }
            }
        }
    }

    @include breakpoint(laptop) {
        padding: 80px 80px 0;

        h2  {
            font-size: 36px;
        }

        h3{
            font-size: 24px;
        }

        p {
            font-size: 18px;
        }

        &_list{
            max-width: 1280px;
            width: 100%;
            
            li {
                overflow: hidden; 

                img {
                    transform: scale(1);
                    transition: all .6s ease;

                    &:hover{
                        transform: scale(1.05);
                    }
                }
            }
        }

        .recoleta &{
            padding: 80px;
        }  

        .gastro_list{
        }
    }

    @include breakpoint(bigdesktop) {
        padding: 100px 100px 0;

        .recoleta &{
            padding: 100px;
        }
    } 
}