// Fonts
$font-title: 'Lora', serif;
$font-base: 'Fira Sans', sans-serif;

// Colors
$white: #fff;
$black: black; // darkblue
$grey: #666565;
$cream: #f7f2e9;
$cream-dark:#f1eada;

$iguazu: #007A5C;
$esmeralda: #006099;
$chapelco: #D11E1C;
$recoleta: #C99A83;