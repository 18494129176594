.we {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $cream;
    color:$grey;

    .stars {
        max-width: 20%;
        height: auto;
        margin-bottom: 10px;
    }

    h2 {
        font-weight: 400;
        margin-bottom: 20px;
        text-align: center;
    }

    h3 {
        font-weight: 400;
        font-size: 28px;
        margin: 42px 0 0;
        text-align: center;
    }

    p {
        text-align: center;
        color: $grey;
        margin-bottom: 16px;
    }

    &_list {
        margin-top: 18px;

        li {
            margin-bottom: 10px;

            img {
                transition: .3s all ease-in-out;
            }

            .flats {
                width: 190px;
                margin: 0 auto;
            }           
            
            &:nth-child(4) {
                margin: 60px 0;
            }
        }
    }

    a.button{
        margin: 32px 0;
        background-color: $cream;
        border: 2px solid $iguazu;
        font-weight: 500;

        span{
            color:$grey;
            font-weight: 500;
        }

        &::before{
            background-color: $iguazu;
            color:$white;
            font-weight: 500;
        }
    }

    .popup-overlay{
        position: absolute;
        display: none;
        opacity: 0;
        transition: opacity .3s ease-in;

        button{
            position: absolute;
            top:0;
            right:0;
            //font-size: 32px;
            background:$cream;
            //color:$white;
            //border: 1px solid $iguazu;
            padding:5px;
            cursor:pointer;

            svg{
                width: 20px;
                fill:$black;
                vertical-align: middle;
            }
        }
        
        &.active{
            opacity:1; /* Hidden by default */
            display: block;
            position: fixed; /* Stay in place */
            z-index: 4; /* Sit on top */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: auto; /* Enable scroll if needed */
            background-color: rgb(0,0,0); /* Fallback color */
            background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

            .popup-content{ 
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #fefefe;
                margin: 15% auto; /* 15% from the top and centered */
                padding: 30px;
                border: 2px solid $iguazu;
                width: 80%; /* Could be more or less, depending on screen size */
                background: $cream;
                text-align: left;

                h2 {
                    font-size: 24px;
                }

                h2, p { 
                    text-align: left;
                }
            }
        }
        
        &.active{
            opacity: 1;
        }
    }

    &_logo{
        max-width: 150px;
    }

    &.promo-verano {
        background: $cream;
        
        h1 {
            display: flex;
            flex-direction: column;
            gap: 18px; 
            margin-bottom: 42px;
        }
        
        h2 {
            font-size: 30px;
            margin-bottom: 12px;
        }

        h3 {
            font-size: 26px;
        }

        .check-promo {
            margin: 24px 0 68px;
            
            .button {
                padding: 12px;
                color: $white;
                border: none;
                background-color: $grey;
                font-weight: 500; 
                
                &:hover {
                    background-color: $white;
                    color: $grey;
                }                
            }
        }

        .items-hotels {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    &.turismo{
        background:$white;
        
        h2{
            span{
                display: block;
                font-style: italic;
            }
        }

        p a{
            color:$grey;
            transition: color 400ms ease;
            &:hover{
                color:$black;
            }
        }

        .turismo_list{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-top: 10px;
            position: relative;

            li{
                margin-bottom: 20px;

                figcaption{
                    color: $grey;
                    margin-bottom: 10px;
                }

                figure{
                    picture{
                        img{
                            cursor: pointer;
                            transition: 0.3s;
                        }
                    }
                }
            }
        }
        
        .modal{
            position:fixed;
            top: 0;
            left: 0;
            background: rgba(0,0,0,0.9);
            height: 100%;
            width: 100%;
            z-index: 100;
            display: none;

            .close{
                position: absolute;
                top:0;
                right: 10px;
                font-size: 50px;
                font-weight: bolder;
                color: $white;
                cursor: pointer;
                z-index: inherit;
            }

            img{
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 95%;
                object-fit: cover;
            }
        }
    }

    &.descubri {
        background: $cream-dark; 
    }
    
    &.events {
        background: $cream;
        
        .chapelco & {
            background: none;
        }
    } 

    .chapelco &.descubri{
        .we_list li{
            img{
                filter: opacity(60%);

                &:hover{
                    filter: opacity(100%);  
                }
            }

            &:nth-child(3) img{
                filter: opacity(100%);
            }
        }
    }

    .recoleta &.descubri{
        .we_list li{
            img{
                filter: opacity(60%);

                &:hover{
                    filter: opacity(100%);  
                }
            }

            &:nth-child(1) img{
                filter: opacity(100%);
            }
        }
    }

    .iguazu &.descubri{
        .we_list li{
            img{
                filter: opacity(60%);

                &:hover{
                    filter: opacity(100%);  
                }
            }

            &:nth-child(2) img{
                filter: opacity(100%);
            }
        }
    }

    .esmeralda &.descubri{
        .we_list li{
            img{
                filter: opacity(60%);

                &:hover{
                    filter: opacity(100%);  
                }
            }

            &:nth-child(4) img{
                filter: opacity(100%);
            }
        }
    }

    @include breakpoint(mobilelandscape) {

        &_list { 
            display: grid;
            grid-template-columns: repeat(2,1fr);
            background:url('../img/argentina.svg');
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: contain;

            li {
                margin-bottom: 0;

                img {
                    width: 65%;
                    height: auto;
                }

                &:nth-child(1) {
                    img {
                        margin:0 auto auto auto;
                    }
                }
                &:nth-child(2) {

                    img {
                        margin:0 auto auto auto;
                    }
                }
                &:nth-child(3) {
                    img {
                        margin:auto auto 0 0;
                    }
                }
                &:nth-child(4) {
                    img {
                        margin:auto auto 0 0;
                    }
                }
            }
        }
    }

    @include breakpoint(phablet) {
        padding:40px;

        h2 {
            font-size: 32px;
            margin-bottom: 30px;
        }

        h3 {
            font-size: 28px;
        }        

        &_list {
            margin-top: 28px;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            background:url('../img/argentina.svg');
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: contain;

            li {
                margin-bottom: 0;

                img {
                    width: 65%;
                    height: auto;
                    transition: .3s all ease;
                }

                &:nth-child(1) {
                    img{
                        margin:0 auto auto auto;
                    }
                }
                &:nth-child(2) {

                    img {
                        margin-left: auto; 
                    } 
                }
                &:nth-child(3) {
                    grid-row: 3 / 4;

                    img{
                        margin:auto auto 0 0;
                    }
                } 
                &:nth-child(4) {
                    grid-row: 2 / 3; 
                    margin: 20px 0;

                    img{
                        margin:auto auto 0 0;
                    }
                }   
                &:nth-child(5) {
                    margin: 20px 0;

                    // img{
                    //     margin:auto auto 0 0;
                    // }
                }   
            }
        }

        a.button{
            margin-bottom: 10px;
        }
 
        &.promo-verano {
            padding: 160px 0 60px;

            #formContacto {
                display: grid;
                width: 100%;
                grid-template-columns: repeat(3, 1fr);
                gap: 14px;

                * {
                    margin: 0;
                }

                .button {
                    grid-column: 3 / 4;
                }
            }

            .items-hotels {
                flex-direction: row;
                margin: 0;
            }
        }

        &.turismo{

            .turismo_list{
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2,1fr);
                column-gap: 20px;

                li{
                    margin-bottom: 20px;
                    figure{

                        figcaption{
                            height: 32px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        }

                        picture{
                            position: relative;
                            padding-top: 70%;
                            width: 100%;
                            overflow: hidden;

                            img{
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }
            }

            .modal{
                img{
                    width: 600px;
                }
            }
        }
    }
    @include breakpoint(tablet) {
        padding:60px;
        
        .stars {
            margin-bottom: 20px;
            max-width: 10%;
        }

        h2 {
            font-size: 42px;
            margin-bottom: 38px;  

            span {
                display: block;
            }
        }

        h3 {
            font-size: 36px;
        }

        p {
            max-width: 60%;
        }

        &_list {
            margin-top: 48px;
            max-width: 75%;
        }

        a.button{
            margin-bottom: 20px;
        }

        &.promo-verano {

            h1 {
                font-size: 42px;
            }   
            
            h2 {
                font-size: 28px;
                margin-bottom: 38px;
            }

            h3 {
                font-size: 28px;
                margin: 0 0 38px;
            }  
        } 

        .popup-overlay{
                
            &.active{
    
                .popup-content{
                    align-items: flex-start;
                    text-align: left;
                    padding: 60px;

                    h2 {
                        font-size: 32px;
                        text-align: left; 
                    }

                    p {
                        text-align: left;
                        max-width: none;
                    }
                }
            }
            
            &.active{
                opacity: 1;
            }

            button{
                svg{
                    width: 30px;
                    transition: all 0.2s ease;                
                }


                &:hover{
                    background:$white;
                    svg{
                        fill:$iguazu;
                    }
                }    
            }
        }

        &.turismo {

            .turismo_list{
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: center;
                flex-wrap: wrap;                

                li{
                    margin-bottom: 30px;
                    width: 31%;

                    figure{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-end;
                        //min-height: 250px;
                    }
                }
            }

            .modal{
                img{
                    width: 750px;
                }
            }
        }
    }

    @include breakpoint(laptop) {
        padding:80px;

        h3 {
            margin-top: 80px;
        }
        p {
            // font-size: 18px;
            max-width: 720px;
        }

        &_list {
            // margin-top: 90px;

            a {
                &:hover img {
                    transform: scale(1.05);
                }
            }
        }

        &.turismo{
            padding-top: 0;
            
            .stars {
                margin-top: -45px;
                width: 140px;
            }

            .turismo_list{
                margin-top: 40px;

                li{
                    margin-bottom: 40px;
                }
            }
        }
    }

    @include breakpoint(bigdesktop) {
        padding: 100px;
    }    
}