.hotels {
    background: $white;
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        color: grey; 
        font-family: 'Lora';
        font-weight: 400;
        text-align: center;
        margin-bottom: 20px;
        
        span {
            font-style: italic; 
            font-weight: bold;
        }
    }   

    h3 {
        color: grey; 
        font-size: 26px;
        font-family: 'Lora';
        font-weight: 400;
        font-style: italic;
        text-align: center;
        margin: 0px;
    }   

    &_list{
        width: 100%;
        margin: 42px 0;

        li {
            margin-bottom: 30px;
        }
    }

    .hotel {
        position: relative;
        overflow: hidden; 
    }
    
    figure {
        position: relative;
        overflow: hidden;
        max-height: 350px;

        figcaption {
            font-weight: 400;
            font-size: 28px;
            font-family: 'Lora';
            color: $white;
            text-align: left;
            position: absolute;
            bottom: 30px;
            left: 30px;
            line-height: 1.2;

            span{
                display: block;
                font-style: italic;
                font-weight: bold;
            }
        }

        &:before {
            // content: 'VER HOTEL';
            font-family: $font-base;
            font-size: 16px;
            color: $white;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 30px;
            width: 100%;
            height: 100%; 
            position: absolute;
            transform: translate(0,0);
            // z-index: 1;
        }

        &.iguazu {
            
            &:before {
                content: 'VER HOTEL';
                background: rgba(0,122,92,.5);
            }            
        }

        &.chapelco {

            &:before {
                content: 'VER HOTEL';
                background: rgba(209,30,28,.5);
            }            
        }

        &.recoleta {

            &:before {
                content: 'VER HOTEL';
                background: rgba(201,154,131,.5);
            }            
        }

        &.flats {

            &:before {
                content: 'VER DEPARTAMENTOS';
                background: rgba(0,96,153,.5);
            }            
        }       
    }

	.tabs {
		position: relative;
		overflow: hidden;
		margin: 0 auto;
		width: 100%;
		font-weight: 300;
		font-size: 1.25em;

		// Nav
		.tabs-nav {
			text-align: center;
			// position: sticky;
			// top: 376px;
            position: relative;
            display: flex;
            margin: 0 auto 42px;
            flex-flow: row wrap;
            justify-content: center;

			h3 {
				position: relative;
				z-index: 1;
				display: block;
				margin: 0;
				text-align: center;
				flex: 1;
			}

			a {
				position: relative;
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				line-height: 2.5;
			}

			// &.tab-current a {
			// 	color: red;
			// }

			a:focus {
				outline: none;
			}
		}
	}  

	.content-wrap {
		position: relative;

		section {
            color: $grey;
			margin: 0 auto;
		    height: 0;
		    opacity: 0;
		    overflow: hidden;
		    visibility: hidden;
		    transition: all .2s ease-in-out;

			&.content-current {
				height: 100%;
    			opacity: 1;
    			visibility: visible;
			}
		}
    }

	.tabs-style-linemove {

		nav {
			// background: yellow;
			
			h3:last-child::before {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 4px;
				background: $grey;
				content: '';
				transition: transform 0.3s;
			}    

            h3:first-child.tab-current ~ h3:last-child::before {
				transform: translate3d(-100%,0,0);
			}

            a {
				padding: 0 0 .6em 0;
				color: $grey;
				// line-height: 1;
				transition: color 0.3s, transform 0.3s;
			}

			h3.tab-current a {
				// color: #2CC185;
				transform: translate3d(0,8px,0);
			}  
        }   
    }
    
    @include breakpoint(mobilelandscape) {

        &_list{

            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 30px;

            li {
                margin: 0;
            }
        }
    }

    @include breakpoint(phablet) {
        padding:40px;

        h2 {
            font-size: 32px;
            margin-bottom: 30px;
        }

        &_list{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 30px;

            li {
                margin: 0;
            }
        }

        .tabs {
            
            .tabs-nav {        
                width: 70%;
                max-width: 640px;
            }
        }

        .content-wrap {
            max-width: 960px;
            margin: 0 auto;
            
            section { 
                
                p {
                    text-align: center;      
                    max-width: 780px;
                    margin: 0 auto;

                }
            }
        }
    }

    @include breakpoint(tablet) {
        padding: 60px;

        &_list{
            max-width: 1180px;
            display: grid;
            grid-template-columns: repeat(3,1fr);
            column-gap: 20px;
            
            li{
                margin-bottom: 0;
            }

            #departamentos & {
                grid-template-columns: repeat(2,1fr);
                max-width: 640px;
                margin: 42px auto 0;
            }
        }
        figure {
            max-height: none;
            
            &:before {
                // font-size: 24px;
            }
        }
    }

    @include breakpoint(laptop) {
        padding: 80px;

        h2{
            font-size: 36px;
        }

        figure {
            display: block;
            
            &:before {
                transition: transform .2s ease-in;
                transition: opacity .3s ease-in;
                // font-size: 28px;
                transform: translate(-100%,0);
                opacity: 0;
            }    
            
            &:hover {
                cursor: pointer;

                &:before {
                    transform: translate(0);
                    opacity: 1; 
                }
            }
        }
    }

    @include breakpoint(desktop) {

        &_list{
            max-width: 1440px;
        }
    }

    @include breakpoint(bigdesktop) {
        padding: 100px;

        h2{
            font-size: 36px;
        }

    }
}