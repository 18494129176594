.location{
    background: $white;
    color: $grey;
    text-align:center;
    padding: 40px 0 0;

    h2 {
        padding:0 20px 0px;
        font-weight: 400;
        font-size: 24px;
        font-family: 'Lora';
        margin-bottom: 10px;
    }

    p {
        padding:0 20px 0px;
        margin-bottom: 20px;
        font-family: 'Fira Sans';
    }

    iframe {
        display: block;
    }

    @include breakpoint(phablet) {

        h2{
            padding:0 40px 0px;
            font-size: 32px;
        }
        p{
            padding:0 40px 0px;
            margin-bottom: 30px;
        }

    }

    @include breakpoint(tablet) {

        h2{
            padding: 0px 60px 0px;
            max-width: 1180px;
            margin:0 auto;
        }

        .chapelco & , .esmeralda & {
            h2{
                padding: 80px 60px 0px;
            }
        }
        .recoleta &{
            h2{
                padding:20px 60px 0;
            }
        }
        p{
            padding: 0px 60px 0px;
            max-width: 75vw;
            margin:0 auto;
            margin-bottom: 40px;
        }
    }

    @include breakpoint(laptop) {

        h2{
            font-size: 36px;
            padding: 0 80px 0px;
        }
        .chapelco & , .esmeralda & {
            
            h2 {
                padding: 120px 80px 32px;
            }
        }

        .recoleta &{

            h2 {
                padding:20px 80px 22px;
            }
        }

        p {
            font-size: 18px;
            padding:0 80px 0px;
            margin-bottom: 60px; 
        }
    }

    @include breakpoint(desktop) {

        &_tagline{
            //max-width: 1440px;
        }
    }
    
    @include breakpoint(bigdesktop) {
        
        h2{
            font-size: 36px;
            padding: 0 100px 0px;
        }
        .chapelco & , .esmeralda & {
            h2{
                padding: 120px 80px 0px;
            }
        }
        .recoleta &{
            h2{
                //padding:100px 100px 0;
            }
        }
        p{
            font-size: 20px;
            padding: 0 100px 0px;
        }

    }
}